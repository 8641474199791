(function _() {
  'use strict';

  const dependencies = [
    'cam.map',
    'cam.auth',
    'cam.player',
    'cam.left-sidebar',
    'cam.services',
    'ngComponentRouter',
    'ngResource',
    'ngSanitize',
    'ngCookies',
    'datePicker'
  ];

  angular
    .module('cam', dependencies)
    .config(['$provide', '$logProvider', ($provide, $logProvider) => {
      $provide.decorator('$log', ['$delegate', 'logger', ($delegate, logger) => logger($delegate)]);
      $logProvider.debugEnabled(true);
    }])
    .value('$routerRootComponent', 'app')
    .run(['$rootScope', 'constants', ($rootScope, constants) => {
      angular.extend($rootScope, rootScope);
      for (const camera of $rootScope.cameras) {
        camera.videoQuality = 'hd';
      }
      rootScope = null;

      window.jwplayer.key = constants.JWPLAYER_KEY;
    }]);
}());
