(function _() {
  'use strict';

  function PlayerController($scope, events, $rootScope, archives, playerUtils,
                            utils, cameras, $location, $sce) {
    const ctrl = this;

    function init() {
      ctrl.datesVisible = false;
      ctrl.inlineStr = 'Укажите дату архива';
      ctrl.downloadArchive = false;
      ctrl.isNext = false;
      ctrl.isPrevious = false;
    }

    function getDateComponents(date) {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString();
      const day = date.getDate().toString();
      const hours = date.getHours().toString();
      const minutes = date.getMinutes().toString();

      return [
        year,
        (month[1] ? month : `0${month}`),
        (day[1] ? day : `0${day}`),
        (hours[1] ? hours : `0${hours}`),
        (minutes[1] ? minutes : `0${minutes}`)
      ];
    }

    function getDateStr(date) {
      const [year, month, day, hours, minutes] = getDateComponents(date);

      return `${year}-${month}-${day}-${hours}${minutes}`;
    }

    function archiveDateChangeHandler(date) {
      const dateStr = getDateStr(date);
      const [year, month, day, hours, minutes] = getDateComponents(date);
      ctrl.inlineStr = `${year}-${month}-${day}-${hours}:${minutes}`;

      archives.getUrls(ctrl.camera.id, dateStr).then(res => {
        if (playerUtils.isActive()) {
          playerUtils.remove();
        }

        playerUtils.setupForArchive(res.data);

        $('.player-download-archive a').attr('href', res.data.download);
        ctrl.downloadArchive = true;

        if (res.data.metadata.next.http.length) {
          ctrl.isNext = true;
          ctrl.next = res.data.metadata.next;
          ctrl.archiveNextDate = new Date(date.getTime() + 10 * 60 * 1000);
          $('.archive-next').css('display', 'none');
        }

        if (res.data.metadata.previous.http.length) {
          ctrl.isPrevious = true;
          ctrl.previous = res.data.metadata.previous;
          ctrl.archivePreviousDate = new Date(date.getTime() - 10 * 60 * 1000);
          $('.archive-previous').css('display', 'none');
        }
      });
    }

    function showPopup(popupContent) {
      popupContent.show();
      popupContent.css('left', '50%');
      popupContent.css('margin-left', '-300px');
      popupContent.css('margin-top', '56px');
      popupContent.css('z-index', '2');
    }

    function playVideo(camera) {
	playerUtils.setup(camera);
    }

    ctrl.$routerCanReuse = () => !ctrl.isInit;

    ctrl.$routerOnActivate = (next) => {
      const id = parseInt(next.params.id, 10);
      const cams = cameras.getCameras();

      for (const cam of cams) {
        if (cam.id === id) {
          ctrl.camera = cam;
          break;
        }
      }

      init();
      const popupContent = $('.player-wrapper');
      const params = { camera_id: id };

      cameras.getCameraInfo(params)
      .then(res => {
        ctrl.archive_duration = res.data.archive_duration;
        ctrl.subtitle = res.data.subtitle;
        popupContent.show();
        showPopup(popupContent);
	if (res.data.flussonic_archive==0) {
	    playVideo(res.data.streams);
	} else {
	    if ($rootScope.user.isAuth) {
		var url='http://';
		if (res.data.flussonic_test==-1) {
		    url+='94.73.235.10';
		} else if (res.data.flussonic_test==-2) {
		    url+='94.73.241.73';
		} else if (res.data.flussonic_test==-3) {
    		    url+='94.73.241.74';
		} else if (res.data.flussonic_test==1) {
		    url+='94.73.241.80';
		} else if (res.data.flussonic_test==2) {
		    url+='134.19.128.90';
		} else if (res.data.flussonic_test==3) {
		    url+='94.73.241.85';
		} else if (res.data.flussonic_test==4) {
		    url+='193.106.168.50';
		} else if (res.data.flussonic_test==5) {
		    url+='94.73.241.81';
		} else if (res.data.flussonic_test==6) {
		    url+='94.73.241.83';
		} else if (res.data.flussonic_test==7) {
		    url+='94.73.241.82';
		} else if (res.data.flussonic_test==9) {
		    url+='94.73.241.87';
		} else if (res.data.flussonic_test==10) {
		    url+='94.73.241.88';
		};
//		var url2=url;
		url = 'http://fluserver.orionnet.online';
		url+=':80/cam'+res.data.id+'/embed.html?autoplay=false&dvr=true&download=true&token='+res.data.flutoken;
		ctrl.iframe=url;
		ctrl.iframeurl=$sce.trustAsResourceUrl(url);
	    } else if (res.data.flussonic_test!=0) {
		var url='http://';
		if (res.data.flussonic_test==-1) {
		    url+='94.73.235.10';
		} else if (res.data.flussonic_test==-2) {
		    url+='94.73.241.73';
		} else if (res.data.flussonic_test==-3) {
		    url+='94.73.241.74';
		} else if (res.data.flussonic_test==1) {
		    url+='94.73.241.80';
		} else if (res.data.flussonic_test==2) {
		    url+='134.19.128.90';
		} else if (res.data.flussonic_test==3) {
		    url+='94.73.241.85';
		} else if (res.data.flussonic_test==4) {
		    url+='193.106.168.50';
		} else if (res.data.flussonic_test==5) {
		    url+='94.73.241.81';
		} else if (res.data.flussonic_test==6) {
		    url+='94.73.241.83';
		} else if (res.data.flussonic_test==7) {
		    url+='94.73.241.82';
		};
		url = 'http://fluserver.orionnet.online';
		url+=':80/cam'+res.data.id+'/embed.html?autoplay=false'; //&dvr=false'; //&token='+res.data.flutoken;
		ctrl.iframe=url;
		ctrl.iframeurl=$sce.trustAsResourceUrl(url);
	    } else {
		playVideo(res.data.streams);
	    };
	};
//	console.log(ctrl);

        if ($rootScope.user.isAuth && ctrl.camera.flussonic_archive==0) {
          archives.getDates(ctrl.camera.id).then(r => {
            const info = r.data;
            const min = info[0];
            let parts = min.split('.');
            ctrl.minDate = new Date(+parts[2], +parts[1] - 1, +parts[0], 0, 0, 0).getTime();

            const max = info[info.length - 1];
            parts = max.split('.');
            ctrl.maxDate = new Date(+parts[2], +parts[1] - 1, +parts[0], 23, 59, 59).getTime();
          });
        }

        ctrl.isInit = true;
      });
    };

    ctrl.auth = utils.isAuth();
    ctrl.isInit = false;

    ctrl.anotherArchive = date => {
      init();
      archiveDateChangeHandler(date);
      $scope.$broadcast(events.ARCHIVE_CHANGE_FROM_BUTTON, { date });
    };

    ctrl.close = () => {
      $location.path('/');
    };

    ctrl.fullscreen = () => {
      console.log('test');
      $('.player').addClass('player-fullscreen');
      $('.player-container').addClass('player-container-fullscreen');
      $('.player-wrapper').addClass('player-wrapper-fullscreen');
      // $('#player_wrapper').css('width','100%').css('height','100%');
      $('span.player-fullscreen').hide();
      $('span.player-unfullscreen').show();
      console.log('test2');
    };

    ctrl.unfullscreen = () => {
      $('.player').removeClass('player-fullscreen');
      $('.player-container').removeClass('player-container-fullscreen');
      $('.player-wrapper').removeClass('player-wrapper-fullscreen');
      $('span.player-fullscreen').show();
      $('span.player-unfullscreen').hide();
    };

    $scope.$on(events.ARCHIVE_DATE_CHANGE, (e, payload) => {
      archiveDateChangeHandler(payload.date);
    });

    $scope.$on(events.ARCHIVE_PLAY_FINISHED, () => {
      if (ctrl.isNext) {
        ctrl.anotherArchive(ctrl.archiveNextDate);
      }
    });

  }

  angular
    .module('cam.player')
    .component('player', {
      templateUrl: 'template/player/player.component.html',
      controller: ['$scope', 'events', '$rootScope', 'archives', 'playerUtils',
                   'utils', 'cameras', '$location', '$sce', PlayerController]
    });
}());
