(function _() {
  'use strict';

  function MapController(cameras, utils, $rootScope, $rootRouter) {
    /*Проверим, если переход с другого сайта, то покажем форму входа*/
    if(new URLSearchParams(window.location.search).has('form')){
      $('.login')[0].click();
    }

    const ctrl = this;

    ctrl.$onInit = () => {
      function init() {
        const bounds = $rootScope.bounds;

        const cams = cameras.getCameras();
        const mapContainer = $('#map');
        const mapState = ymaps.util.bounds.getCenterAndZoom(bounds, [
          mapContainer.width(),
          mapContainer.height()
        ]);

        mapState.controls = ['trafficControl'];

        if (mapState.zoom > 18) {
          mapState.zoom = 18;
        }

        ctrl.map = new ymaps.Map('map', mapState);
        ctrl.map.controls.add('zoomControl', { float: 'none', position: { top: 60, right: 10 } });

        const geoObjects = [];

        for (const camera of cams) {
          const marker = utils.createMarker(camera);
          const geoObj = new ymaps.GeoObject(marker.params, marker.options);

          geoObj.id = camera.id;
          // ctrl.map.geoObjects.add(geoObj);
          geoObj.events.add('click', () => {
            $rootRouter.navigate(['Player', { id: camera.id }]);
          });

          geoObjects.push(geoObj);
        }

        const clusterer = new ymaps.Clusterer();
        clusterer.add(geoObjects);
        ctrl.map.geoObjects.add(clusterer);
      }

      ymaps.ready(init);
    };
  }

  angular
    .module('cam.map')
    .component('map', {
      templateUrl: 'template/map/map.component.html',
      controller: ['cameras', 'utils', '$rootScope', '$rootRouter', MapController],
      require: {
        appCtrl: '^app'
      }
    });
}());
