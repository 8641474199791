(function _() {
  'use strict';

  function Buildings($resource) {
    const buildings = $resource('api/v1/web/organizations/:orgId/buildings', { orgId: '@id' });

    function get(id) {
      return buildings.query({ orgId: id }).$promise;
    }

    return {
      get
    };
  }

  angular
    .module('cam.auth')
    .factory('buildings', ['$resource', Buildings]);
}());
