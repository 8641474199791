(function _() {
  'use strict';

  function Logger() {
    const date = new Date().toString();

    return $delegate => ({
      log: (...args) => {
        $delegate.log(date);
        $delegate.log.apply(null, args);
      },
      info: (...args) => {
        $delegate.info(date);
        $delegate.info.apply(null, args);
      },
      error: (...args) => {
        $delegate.error(date);
        $delegate.error.apply(null, args);
      },
      warn: (...args) => {
        $delegate.warn(date);
        $delegate.warn.apply(null, args);
      },
      debug: (...args) => {
        $delegate.debug(date);
        $delegate.debug.apply(null, args);
      }
    });
  }

  angular
    .module('cam.services')
    .factory('logger', Logger);
}());
