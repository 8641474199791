(function _() {
  'use strict';

  function AppController(utils) {
    const ctrl = this;
    ctrl.previewImage = camera => utils.getImageSrcFromCamera(camera);
  }

  angular
    .module('cam')
    .component('app', {
      templateUrl: 'template/app.component.html',
      controller: ['utils', AppController],
      $routeConfig: [
        { path: '/player/:id', name: 'Player', component: 'player' },
      ]
    });
}());
