(function _() {
  'use strict';

  function Settings($cookies) {
    const settings = {};

    function getExpires() {
      const now = new Date();
      return new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
    }

    settings.isOpenLeftSidebar = () => $cookies.get('SETTINGS_LEFT_SIDEBAR_OPEN');

    settings.setOpenLeftSidebar = (value) => {
      $cookies.put('SETTINGS_LEFT_SIDEBAR_OPEN', value, { expires: getExpires() });
    };

    settings.getVideoQuality = () => $cookies.get('SETTINGS_VIDEO_QUALITY');

    settings.setVideoQuality = (value) => {
      $cookies.put('SETTINGS_VIDEO_QUALITY', value, { expires: getExpires() });
    };

    return settings;
  }

  angular
    .module('cam.services')
    .factory('settings', ['$cookies', Settings]);
}());
