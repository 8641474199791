(function _() {
  'use strict';

  function Archives($http) {
    function getDates(id) {
      return $http.get('api/v1/web/archives/dates', { params: { camera_id: id } });
    }

    function getUrls(id, date) {
      return $http.get('api/v1/web/archives/links', {
        params: { camera_id: id, date }
      });
    }

    return {
      getUrls,
      getDates
    };
  }

  angular
    .module('cam.player')
    .factory('archives', ['$http', Archives]);
}());
