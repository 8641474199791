(function _() {
  'use strict';

  function LeftSidebarController(settings, cameras, utils, $rootRouter) {
    const ctrl = this;
    ctrl.open = settings.isOpenLeftSidebar() || true;
    ctrl.cameras = cameras.getCameras();

    // Init baron
    const params = {
      scroller: '.scroller',
      bar: '.scroller__bar',
      track: '.scroller__track'
    };
    baron(params);

    ctrl.toggleSidebar = () => {
      ctrl.open = (ctrl.open === 'true' || ctrl.open === true) ? false : true;
      settings.setOpenLeftSidebar(ctrl.open);
    };

    ctrl.search = item => {
      if (ctrl.query === undefined
        || item.title.toLowerCase().indexOf(ctrl.query.toLowerCase()) !== -1) {
        return true;
      }

      return false;
    };
    ctrl.previewImage = camera => utils.getImageSrcFromCamera(camera);

    ctrl.openPlayer = (id) => {
      console.log(ctrl);
      $rootRouter.navigate(['MapCoreApp', 'Player', { id }]);
    };
  }

  angular
    .module('cam.left-sidebar')
    .component('leftSidebar', {
      templateUrl: 'template/left-sidebar/left-sidebar.component.html',
      controller: ['settings', 'cameras', 'utils', '$rootRouter', LeftSidebarController],
      require: {
        appCtrl: '^app'
      },
      bindings: {
        $router: '<'
      }
    });
}());
