(function _() {
  'use strict';

  function Cameras($rootScope, $http) {
    return {
      getCameras: () => $rootScope.cameras,
      getCameraInfo: (params) => $http({
        url: 'api/v1/web/camera_info',
        method: 'GET',
        params
      })
    };
  }

  angular
    .module('cam.services')
    .factory('cameras', ['$rootScope', '$http', Cameras]);
}());
