(function _() {
  'use strict';

  function Auth($http, $sanitize, organizations, buildings, $scope, $filter) {
    const vm = this;
    const chooseBuilding = 'Выберите дом';

    vm.codeSent = false;
    vm.orgStr = 'Выберите организацию ';
    vm.buildingStr = chooseBuilding;

    vm.loginByOrg = false;
    vm.buttonText = 'Вход для клиентов организаций';

    vm.orgsVisible = false;
    vm.buildingsVisible = false;

    vm.orgChoosed = false;
    vm.buildingChoosed = false;

    let activeOrg = {};
    let activeBuilding = {};

    vm.orgs = [];
    vm.buildings = [];

    function getPhone() {
      return vm.mobile.slice(2).replace(new RegExp('[()-]', 'g'), '');
    }

    function getCredentials() {
      const credentials = {};
      credentials.organization_id = $sanitize(activeOrg.id);
      credentials.building_id = $sanitize(activeBuilding.id);
      credentials.apartment = $sanitize(vm.apartment);
      credentials.mobile = $sanitize(getPhone());

      return credentials;
    }

    function getCredentialsWithCode() {
      const credentials = getCredentials();
      credentials.code = $sanitize(vm.code);

      return credentials;
    }

    vm.toggleLogin = () => {
      vm.error = '';
      vm.success = '';

      vm.loginByOrg = !vm.loginByOrg;
      if (vm.loginByOrg) {
        vm.buttonText = 'Вход с помощью логина';

        if (!vm.orgs.length) {
          vm.orgsLoading = true;
          organizations
            .get()
            .then(data => {
              vm.orgsLoading = false;
              angular.forEach(data, item => {
                vm.orgs.push(item);
              });
            });
        }
      } else {
        vm.buttonText = 'Вход для клиентов организаций';
      }
    };

    vm.orgChoose = org => {
      vm.orgChoosed = true;

      if (!angular.isDefined(activeOrg.id) || activeOrg.id !== org.id) {
        vm.buildingsLoading = true;
        buildings
          .get(org.id)
          .then(data => {
            vm.buildingsLoading = false;
            vm.buildings = [];
            for (const building of data) {
              vm.buildings.push(building);
            }
          });

        activeOrg = org;
        activeBuilding = {};

        vm.orgStr = org.title;
        vm.buildingStr = chooseBuilding;
      }
    };

    vm.buildingChoose = building => {
      vm.buildingChoosed = true;

      activeBuilding = building;
      vm.buildingStr = building.title;

      $('#mobile').on('focus', (e) => {
        const target = $(e.currentTarget);

        if (target.val().length <= 1) {
          target.val('+7');
        }
      });
    };

    // Phone number
    $scope.$watch('auth.mobile', newValue => {
      const value = String(newValue);
      const number = value.replace(/[^+0-9]+/g, '');

      vm.mobile = $filter('phoneNumber')(number);
    });

    // Code
    $scope.$watch('auth.code', value => {
      if (value !== undefined) {
        vm.code = $filter('authCode')(value);
      }
    });

    vm.loginAction = () => {
      const credentials = {};
      credentials.login = $sanitize(vm.login);
      credentials.password = $sanitize(vm.password);

      vm.dataLoading = true;
      $http
        .post('auth/standalone/login', credentials)
        .then(() => {
          vm.dataLoading = false;
          location.reload();
        }, err => {
          vm.dataLoading = false;
          vm.error = err.data.errors.value;
        });
    };

    vm.loginByCode = () => {
      vm.dataLoading = true;
      $http
        .post('auth/org/login', getCredentialsWithCode())
        .then(() => {
          vm.dataLoading = false;
          location.reload();
        }, err => {
          vm.dataLoading = false;
          vm.error = err.data.errors.value;
        });
    };

    vm.getCode = () => {
      vm.gettingCode = true;
      $http
        .post('auth/org/get-code', getCredentials())
        .then(res => {
          vm.gettingCode = false;
          vm.codeSent = true;
          vm.success = res.data.message;
        }, err => {
          vm.gettingCode = false;
          vm.error = err.data.errors.value;
          vm.codeSent = false;
        });
    };
  }

  angular
    .module('cam.auth')
    .controller('Auth', [
      '$http',
      '$sanitize',
      'organizations',
      'buildings',
      '$scope',
      '$filter',
      Auth
    ]);
}());
