(function _() {
  'use strict';

  function onClosePlayer(playerUtils) {
    return {
      restrict: 'A',
      link: (scope, element) => {
        $(element).on('click', () => {
          if (playerUtils.isActive()) {
            playerUtils.remove();
          }
          $('player').remove();
        });
      }
    };
  }

  function onArchiveButtonsEvents(events) {
    return {
      restrict: 'A',
      link: (scope) => {
        scope.$on(events.ARCHIVE_DATE_CHANGE, () => {
          const prev = $('.archive-previous');
          const next = $('.archive-next');
          const player = $('.player');
          player.on('mouseover', () => {
            prev.css('display', 'inline');
            next.css('display', 'inline');
          });

          player.on('mouseout', () => {
            prev.css('display', 'none');
            next.css('display', 'none');
          });
        });
      }
    };
  }

  angular
    .module('cam.player')
    .directive('onClosePlayer', ['playerUtils', '$location', onClosePlayer])
    .directive('onArchiveButtonsEvents', ['events', onArchiveButtonsEvents]);
}());
