(function _() {
  'use strict';

  function phoneNumber() {
    return number => {
      if (number.length <= 2) {
        return number;
      }

      const num = String(number);

      let formattedNumber = num;

      const first = num.slice(0, 2);
      const area = num.slice(2, 5);
      const front = num.slice(5, 8);
      const backOne = num.slice(8, 10);
      const backTwo = num.slice(10, 12);

      if (front) {
        formattedNumber = `${first}(${area})${front}`;
      }

      if (backOne) {
        formattedNumber += `-${backOne}`;
      }

      if (backTwo) {
        formattedNumber += `-${backTwo}`;
      }

      return formattedNumber;
    };
  }

  function authCode() {
    return code => code.replace(/[^+0-9]+/g, '').slice(0, 6);
  }

  angular
  .module('cam.auth')
  .filter('phoneNumber', [phoneNumber])
  .filter('authCode', [authCode]);
}());
