(function _() {
  'use strict';

  function Utils($window, $rootScope) {
    function createMarker(camera) {
      const params = {
        geometry: {
          type: 'Point',
          coordinates: [camera.latitude, camera.longitude]
        },
        properties: {
          iconContent: '',
          hintContent: camera.title
        }
      };

      const options = { preset: 'islands#darkBlueDotIcon' };
      const marker = camera.marker;

      if (marker !== '.') {
        if (marker.length === 1) {
          options.preset = 'islands#darkBlueIcon';
        } else if (marker.length > 1) {
          options.preset = 'islands#darkBlueStretchyIcon';
        }

        params.properties.iconContent = marker;
      }

      return { params, options };
    }

    function getImageSrcFromCamera(camera) {
      return `api/v1/web/preview_images/${camera.id}?flutoken=${$rootScope.flutoken}`;
    }

    function getBounds(cameras) {
      let minLon;
      let minLat;
      let maxLon;
      let maxLat;

      for (const camera of cameras) {
        const lat = parseFloat(camera.latitude);
        const lon = parseFloat(camera.longitude);

        if (lon < minLon || minLon === undefined) {
          minLon = lon;
        }

        if (lon > maxLon || maxLon === undefined) {
          maxLon = lon;
        }

        if (lat < minLat || minLat === undefined) {
          minLat = lat;
        }

        if (lat > maxLat || maxLat === undefined) {
          maxLat = lat;
        }
      }

      return [[minLon, minLat], [maxLon, maxLat]];
    }

    function isiOSDevice() {
      const userAgent = $window.navigator.userAgent || $window.navigator.vendor || $window.opera;

      return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)
        || userAgent.match(/Mac OS X/i);
    }

    function isAuth() {
      return $rootScope.user.isAuth;
    }

    return {
      getBounds,
      isiOSDevice,
      createMarker,
      getImageSrcFromCamera,
      isAuth
    };
  }

  angular
    .module('cam.services')
    .factory('utils', ['$window', '$rootScope', Utils]);
}());
