(function _() {
  'use strict';

  function VideoSettingsController(cameras, playerUtils) {
    const ctrl = this;

    function getQualityString(quality) {
      return `Качество видео (${quality.toUpperCase()})`;
    }

    ctrl.videoSettings = [
      {
        name: 'Стандартное',
        value: 'sd'
      },
      {
        name: 'Высокое',
        value: 'hd'
      }
    ];

    let videoQuality = 'hd';

    ctrl.qualityString = getQualityString(videoQuality);
    ctrl.isVisible = false;

    ctrl.choose = (value) => {
      ctrl.isVisible = false;
      ctrl.qualityString = getQualityString(value);

      if (value !== videoQuality) {
        videoQuality = value;
        const params = {
          camera_id: ctrl.camera.id,
          video_quality: videoQuality
        };

        cameras.getCameraInfo(params)
        .then(res => {
          if (playerUtils.isActive()) {
            playerUtils.remove();
            playerUtils.setup(res.data.streams);
          }
        });
      }
    };
  }

  angular
    .module('cam.player')
    .component('videoSettings', {
      templateUrl: 'template/player/video-settings.component.html',
      controller: ['cameras', 'playerUtils', VideoSettingsController],
      bindings: {
        camera: '<'
      }
    });
}());
