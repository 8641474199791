(function _() {
  'use strict';

  function PlayerUtils($rootScope, events) {
    function commonOptions() {
      return {
        // width: 720,
        // height: 405,
        width: '100%',
        height: '100%',
        autostart: true,
        androidhls: true,
        sources: [],
        events: {
          onComplete: () => {
            $rootScope.$broadcast(events.ARCHIVE_PLAY_FINISHED, {});
          }
        }
      };
    }

    this.setup = sources => {
      const jwplayerOptions = commonOptions();

      jwplayerOptions.sources = [
        { file: `http://${sources.http}` },
        { file: `rtmp://${sources.rtmp}` }
      ];

      jwplayer('player').setup(jwplayerOptions);
    };

    this.setupForArchive = data => {
      const jwplayerOptions = commonOptions();

      jwplayerOptions.sources = [
        { file: data.http }
      ];

      jwplayer('player').setup(jwplayerOptions);
    };

    this.remove = () => {
	jwplayer('player').remove();
    };

    this.isActive = () => $('#player').length && jwplayer('player').getState() !== undefined;
  }

  angular
    .module('cam.player')
    .service('playerUtils', ['$rootScope', 'events', PlayerUtils]);
}());
