(function _() {
  'use strict';

  function Organizations($resource) {
    const organizations = $resource('api/v1/web/organizations', {});

    function get() {
      return organizations.query().$promise;
    }

    return {
      get
    };
  }

  angular
    .module('cam.auth')
    .factory('organizations', ['$resource', Organizations]);
}());
